import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const plaizxwatizen = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="PLAIZ x WATIZ - Plaiz, the fashion social network"/>
    <div>
    <h1 className="p1" style={{color:"white", marginTop:100}}>PLAIZ joins the WATIZ family✨</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft" style={{marginBottom:100}}><br/>
          <b></b>
            <br/>

            To our 50 000 users, we would like to thank you after 5 amazing years. We have been acquired by the best French fashion app : Watiz
            <br/><br/>
            <a target="_blank" href="https://watiz.io">WATIZ</a> is an app that makes shopping as easy as taking a picture.
            <br/><br/>
            We believe in our common vision of the fashion world.
            <br/><br/>
            To our community, this means that we are going to shut down the Plaiz app, and you will have until September 26 at 00:00am to download your data if needed, since Plaiz and Watiz will not keep any of your content.
            <br/><br/>
            We would like to thank you once and for being part of the Plaiz family and welcome you to the Watiz family, don't forget to download the <a target="_blank" href="https://watiz.io">WATIZ</a> app ! Great things are coming, stay tuned !
            <br/><br/>
            Team Plaiz

        </p>
        
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default plaizxwatizen

const styles = {
  backToHomeLink: {
    color: "white",
  },
}